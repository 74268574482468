import React, { useState } from 'react';
import Cookies from 'js-cookie';

import { RiCarLine, RiMotorbikeLine, RiCarFill } from 'react-icons/ri';
import { FaWhatsapp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import paymentMethod1 from '../img/fpx-logo.png';
import paymentMethod2 from '../img/atome-logo.png';
import paymentMethod3 from '../img/shopeepay-logo.png';
import paymentMethod4 from '../img/grabpay-logo.jpg';
import paymentMethod5 from '../img/visa-mastercard.png';
import SendEmail from './SendEmail';

function Hero() {
  const [selectedVehicle, setSelectedVehicle] = useState('car');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    // vehicle_type: 'car',
    // nama: 'Akmal',
    // id_type: '1',
    // id_number: '911119146881',
    // plate_no: 'wvy1664',
    // email: 'khaiakmals@gmail.com',
    // whatsapp_no: '0172284869',
    // status: 'new',
    vehicle_type: 'car',
    nama: '',
    id_type: '1',
    id_number: '',
    plate_no: '',
    email: '',
    whatsapp_no: '',
    status: 'new',
  });

  const to = 'kasirenew@gmail.com';
  const subject = `Permintaan Sebut Harga - ${formData.plate_no}`;
  // const text = `Permintaan sebut harga baharu untuk kenderaan ABC1234.`;
  const text = `Log Masuk ke admin panel untuk menghantar sebut harga.`;

  const handleVehicleChange = (event) => {
    setSelectedVehicle(event.target.value);
    setFormData({ ...formData, vehicle_type: event.target.value });
  };

  const navigate = useNavigate();

  const constructWhatsAppLink = () => {
    const message = `Permintaan Sebut Harga KasiRenew.com:\nNama: ${formData.nama}\nPlate No: ${formData.plate_no}\n`;

    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/601120888930?text=${encodedMessage}`;
    return whatsappLink;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      // Get the 'ref' value from the cookie
      const refValue = Cookies.get('ref');
  
      // Set 'direct' as the 'referral' value if 'ref' is empty
      const referralValue = refValue || 'direct';
  
      // Add 'referral' to the form data
      const updatedFormData = { ...formData, referral: referralValue };
  
      const response = await axios.post('https://api.kasirenew.com/api/clients', updatedFormData);
  
      if (response.status === 201) {
        SendEmail({ to, subject, text });
        const whatsappLink = constructWhatsAppLink();
        window.location.href = whatsappLink;
        navigate('/thank-you');
      } else {
        console.error('Form submission failed.');
      }
    } catch (error) {
      console.error('An error occurred while submitting the form.', error);
    }
  };
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <section className="mt-10 bg-purple-500 py-16 px-8 md:px-16 lg:px-24 xl:px-32">
      {loading && (<div className="fixed inset-0 h-screen w-full bg-white bg-opacity-80 flex flex-col items-center justify-center space-y-5">
        <RiCarFill className="text-4xl text-purple-600 animate-spin" />
      </div>)}
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        <div className="md:w-3/5 text-white mb-2">
          <h1 className="text-6xl md:text-6xl font-bold mb-4">Renew <span className='text-yellow-200'>Dahulu</span>, Bayar <span className='text-yellow-200'>Kemudian</span></h1>
          <p className="text-lg md:text-xl mb-8">Dengan SPayLater, Grab PayLater & Kad Kredit</p>
          <div className="flex mt-4 flex-wrap">
            <img src={paymentMethod1} alt="Payment Method 1" className="m-2 h-8" />
            {/* <img src={paymentMethod2} alt="Payment Method 2" className="m-2 h-8" /> */}
            <img src={paymentMethod3} alt="Payment Method 3" className="m-2 h-8" />
            <img src={paymentMethod4} alt="Payment Method 4" className="m-2 h-8" />
            <img src={paymentMethod5} alt="Payment Method 5" className="m-2 h-8" />
          </div>

        </div>
        <div className="md:w-2/5">
          <form onSubmit={handleFormSubmit} className="bg-white shadow-md rounded-lg p-6">
            <div className="mb-4">
              <div className="flex gap-4">
                <div className="flex items-center w-1/2">
                  <input
                    type="radio"
                    id="car"
                    name="vehicle_type"
                    value="car"
                    checked={selectedVehicle === 'car'}
                    onChange={handleVehicleChange}
                    className="hidden"
                  />
                  <label
                    htmlFor="car"
                    className={`w-full px-4 py-2 rounded-md cursor-pointer select-none flex items-center transition-colors ease-in-out duration-300 ${
                      selectedVehicle === 'car'
                        ? 'bg-gray-500 text-white'
                        : 'bg-white border border-gray-500 text-gray-500'
                    }`}
                  >
                    <RiCarLine className="w-5 h-5 mr-2" /> Car
                  </label>
                </div>
                <div className="flex items-center w-1/2">
                  <input
                    type="radio"
                    id="motorcycle"
                    name="vehicle_type"
                    value="motorcycle"
                    checked={selectedVehicle === 'motorcycle'}
                    onChange={handleVehicleChange}
                    className="hidden"
                  />
                  <label
                    htmlFor="motorcycle"
                    className={`w-full px-4 py-2 rounded-md cursor-pointer select-none flex items-center transition-colors ease-in-out duration-300 ${
                      selectedVehicle === 'motorcycle'
                        ? 'bg-gray-500 text-white'
                        : 'bg-white border border-gray-500 text-gray-500'
                    }`}
                  >
                    <RiMotorbikeLine className="w-5 h-5 mr-2" /> Motorcycle
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="nama" className="block text-gray-800 font-semibold mb-2">Nama</label>
              <input
                type="text"
                id="nama"
                name="nama"
                placeholder="Masukkan nama disini"
                className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
                required
                onChange={handleInputChange}
                value={formData.nama}
              />
            </div>
            <div className="mb-4 flex gap-4">
              <div className="w-1/4">
                <label htmlFor="id_type" className="block text-gray-800 font-semibold mb-2">ID Type</label>
                <select
                  id="id_type"
                  name="id_type"
                  className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
                  onChange={handleInputChange}
                  value={formData.id_type}
                >
                  <option value="1">NRIC</option>
                  <option value="2">Passport</option>
                  <option value="3">Army/Police</option>
                </select>
              </div>
              <div className="w-3/4">
                <label htmlFor="id_number" className="block text-gray-800 font-semibold mb-2">ID Number</label>
                <input
                  type="text"
                  id="id_number"
                  name="id_number"
                  placeholder="XXXXXX-XX-XXXX"
                  className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
                  required
                  onChange={handleInputChange}
                  value={formData.id_number}
                />
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="plate_no" className="block text-gray-800 font-semibold mb-2">Plate No</label>
              <input
                type="text"
                id="plate_no"
                name="plate_no"
                placeholder="ABC1234"
                className="w-full p-3 rounded-md border border-gray-300 uppercase focus:outline-none focus:ring focus:ring-purple-500"
                required
                onChange={handleInputChange}
                value={formData.plate_no}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-800 font-semibold mb-2">Emel</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Masukkan emel disini"
                className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
                required
                onChange={handleInputChange}
                value={formData.email}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="whatsapp_no" className="block text-gray-800 font-semibold mb-2">No WhatsApp</label>
              <input
                type="text"
                id="whatsapp_no"
                name="whatsapp_no"
                placeholder="0123456789"
                className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
                required
                onChange={handleInputChange}
                value={formData.whatsapp_no}
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="flex space-x-2 md:space-x-4 w-full bg-purple-500 text-white p-3 rounded-md font-semibold hover:bg-purple-600 focus:outline-none focus:ring focus:ring-purple-500"
              >
                <FaWhatsapp className="text-white text-2xl" />
                <p>WhatsApp untuk Mohon Quotation Percuma</p>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Hero;
