import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios"; // Import Axios library
import FPXPayment from "../components/payments/FPXPayment";
import ShopeePayPayment from "../components/payments/ShopeePayPayment";
import OnlineTransfer from "../components/payments/OnlineTransfer";
import BnplPayment from "../components/payments/BnplPayment";
import CardPayment from "../components/payments/CardPayment";
import EwalletPayment from "../components/payments/EwalletPayment";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

function Checkout() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);

  const client_id = localStorage.getItem("client_id");
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [totalPrice, setTotalPrice] = useState(0); // Initialize totalPrice
  const [price, setPrice] = useState(0);
  const [roadtax, setRoadtax] = useState(0);
  const [roadtaxDigital, setRoadtaxDigital] = useState(0);
  const [roadtaxFizikal, setRoadtaxFizikal] = useState(0);
  const [windscreen, setWindscreen] = useState(0);
  const [windscreenCover, setWindscreenCover] = useState(0);
  const [specialPerils, setSpecialPerils] = useState(0);
  const [towing, setTowing] = useState(0);
  const [paymentEnabled, setPaymentEnabled] = useState(true); // Initialize paymentEnabled
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState(""); // Name from API
  const [email, setEmail] = useState(""); // Email from API
  const [mobile, setMobile] = useState(""); // Mobile from API
  const [amountFpx, setAmountFpx] = useState(0);
  const [amountEwallet, setAmountEwallet] = useState(0);
  const [amountCard, setAmountCard] = useState(0);
  const [amountBnpl, setAmountBnpl] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");

  const fpxFees = 1.10;
  const bnplFees = totalPrice / 0.935 - totalPrice;
  const cardFees = totalPrice / 0.982 - totalPrice;
  const ewalletFees = totalPrice / 0.986 - totalPrice;

  useEffect(() => {
    // Make an Axios request to fetch data from the 'carts' table based on 'client_id'
    axios
      .get(`https://api.kasirenew.com/api/cartclient/${client_id}`)
      .then((response) => {
        const cartItem = response.data;

        if (cartItem) {
          // Calculate totalPrice based on the price and roadtax from cartItem
          const {
            price,
            roadtax,
            roadtax_digital,
            roadtax_fizikal,
            windscreen,
            windscreen_cover,
            special_perils,
            towing,
          } = cartItem;
          const calculatedTotalPrice =
            parseFloat(price) +
            parseFloat(roadtax) +
            parseFloat(roadtax_digital) +
            parseFloat(roadtax_fizikal) +
            parseFloat(windscreen) +
            parseFloat(special_perils) +
            parseFloat(towing);
          setTotalPrice(calculatedTotalPrice.toFixed(2));

          // Set the roadtax value
          setPrice(parseFloat(price).toFixed(2));
          setRoadtax(parseFloat(roadtax).toFixed(2));
          setRoadtaxDigital(parseFloat(roadtax_digital).toFixed(2));
          setRoadtaxFizikal(parseFloat(roadtax_fizikal).toFixed(2));
          setWindscreen(parseFloat(windscreen).toFixed(2));
          setWindscreenCover(parseFloat(windscreen_cover).toFixed(2));
          setSpecialPerils(parseFloat(special_perils).toFixed(2));
          setTowing(parseFloat(towing).toFixed(2));
        } else {
          // Handle the case when the cartItem is not found
          // You can set totalPrice to 0 or handle it differently based on your needs
          setTotalPrice(0);
          setRoadtax(0);
          setPaymentEnabled(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching cart data:", error);
        setIsLoading(false);
      });

    // Fetch name, email, and mobile from the API
    axios
      .get(`https://api.kasirenew.com/api/clients/${client_id}`)
      .then((response) => {
        const clientData = response.data;
        if (clientData) {
          setName(clientData.nama);
          setEmail(clientData.email);
          setMobile(clientData.whatsapp_no);
          localStorage.setItem("plate_no", clientData.plate_no);

          const calculatedAmountFpx = (parseFloat(totalPrice) + 1.1) * 100;
          const calculatedAmountBnpl = (parseFloat(totalPrice) / 0.935) * 100;
          const calculatedEwallet = (parseFloat(totalPrice) / 0.986) * 100;
          const calculatedCard = (parseFloat(totalPrice) / 0.982) * 100;

          setAmountFpx(calculatedAmountFpx.toFixed(0));
          setAmountBnpl(calculatedAmountBnpl.toFixed(0));
          setAmountEwallet(calculatedEwallet.toFixed(0));
          setAmountCard(calculatedCard.toFixed(0));
        }
      })
      .catch((error) => {
        console.error("Error fetching client data:", error);
      });
  }, [client_id, totalPrice]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div>
      <Header />
      {isLoading ? (
        <div className="h-screen flex items-center justify-center bg-gray-100">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-600"></div>
        </div>
      ) : (
        <div className="bg-gray-100 min-h-screen mt-14 py-16 px-4 md:px-8 lg:px-12 xl:px-16">
          <div className="container mx-auto">
            <h1 className="text-2xl md:text-3xl font-bold text-center mb-8">
              Checkout
            </h1>
            <div className="bg-white rounded-lg shadow-md p-6 max-w-md mx-auto">
              <p className="text-xl text-center mt-5">Jumlah:</p>
              <p className="text-3xl font-bold text-center mb-4">
                RM{totalPrice}
              </p>
              <div
                onClick={() => setShowBreakdown(!showBreakdown)}
                className="flex justify-center items-center mb-8"
              >
                <h2 className="">Price Breakdown</h2>
                <p className="text-gray-700 focus:outline-none">
                  {showBreakdown ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                </p>
              </div>
              {showBreakdown && (
                <ul className="mt-4 mb-8 p-10 bg-purple-500 text-white rounded-lg">
                  <li className="flex justify-between mb-2">
                    <span>Comprehensive Takaful (selepas NCD)</span>
                    <span className="font-semibold">RM{price}</span>
                  </li>

                  {roadtax > 0 && (
                    <div>
                      <li className="flex justify-between mb-2">
                        <span>Roadtax</span>
                        <span className="font-semibold">RM{roadtax}</span>
                      </li>
                      <div className="mb-2 bg-white p-3 text-sm rounded-lg text-gray-600">
                        {roadtaxDigital > 0 && (
                          <li className="flex justify-between">
                            <span>+ Digital</span>
                            <span className="font-semibold">+RM{roadtaxDigital}</span>
                          </li>
                        )}
                        {roadtaxFizikal > 0 && (
                          <li className="flex justify-between">
                            <span>+ Fizikal</span>
                            <span className="font-semibold">+RM{roadtaxFizikal}</span>
                          </li>
                        )}
                      </div>
                    </div>
                  )}
                  {windscreen > 0 && (
                    <li className="flex justify-between mb-2">
                      <span>Windscreen (Cover: RM{windscreenCover})</span>
                      <span className="font-semibold">RM{windscreen}</span>
                    </li>
                  )}
                  {specialPerils > 0 && (
                    <li className="flex justify-between mb-2">
                      <span>Special Perils (bencana alam)</span>
                      <span className="font-semibold">RM{specialPerils}</span>
                    </li>
                  )}
                  {towing > 0 && (
                    <li className="flex justify-between mb-2">
                      <span>Towing</span>
                      <span className="font-semibold">RM{towing}</span>
                    </li>
                  )}
                </ul>
              )}
              <div className="mb-6">
                {/* <p className="text-lg font-semibold text-center mb-5">Pilih kaedah pembayaran:</p> */}
                <div className="">
                  <select
                    className="block w-full px-4 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                    value={selectedOption}
                    onChange={(e) => {
                      setSelectedOption(e.target.value);
                      handleTabChange(e.target.value);
                    }}
                  >
                    <option value="">Pilih Kaedah Pembayaran</option>
                    <option value="shopeepay">QR Code atau SPayLater</option>
                    <option value="transfer">Direct Online Transfer</option>
                    <option value="fpx">FPX (+RM{fpxFees.toFixed(2)})</option>
                    <option value="card">Kad Kredit/Kad Debit (+RM{cardFees.toFixed(2)})</option>
                    <option value="ewallet">eWallet (+RM{ewalletFees.toFixed(2)})</option>
                    <option value="bnpl">Grab PayLater (+RM{bnplFees.toFixed(2)})</option>
                  </select>
                </div>
              </div>
              {selectedTab === "fpx" && (
                <FPXPayment
                  totalPrice={totalPrice}
                  client_id={client_id}
                  mobile={mobile}
                  amount={amountFpx}
                  email={email}
                  name={name}
                />
              )}
              {selectedTab === "bnpl" && (
                <BnplPayment
                  totalPrice={totalPrice}
                  client_id={client_id}
                  mobile={mobile}
                  amount={amountBnpl}
                  email={email}
                  name={name}
                />
              )}
              {selectedTab === "card" && (
                <CardPayment
                  totalPrice={totalPrice}
                  client_id={client_id}
                  mobile={mobile}
                  amount={amountCard}
                  email={email}
                  name={name}
                />
              )}
              {selectedTab === "ewallet" && (
                <EwalletPayment
                  totalPrice={totalPrice}
                  client_id={client_id}
                  mobile={mobile}
                  amount={amountEwallet}
                  email={email}
                  name={name}
                />
              )}
              {selectedTab === "shopeepay" && (
                <ShopeePayPayment totalPrice={totalPrice} />
              )}
              {selectedTab === "transfer" && (
                <OnlineTransfer totalPrice={totalPrice} />
              )}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Checkout;
